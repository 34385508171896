import(/* webpackMode: "eager" */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\src\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\src\\components\\auth\\AuthProvider2.js");
;
import(/* webpackMode: "eager", webpackExports: ["GTM_ID"] */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\src\\lib\\gtm.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\src\\redux\\provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\src\\theme\\AntProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Andres\\Desktop\\JSS\\genesis\\website\\frontend\\src\\theme\\StyledProvider.js");
